<template>
    <base-card>
        <v-card-text>
            <div
                class="d-flex justify-space-between align-center flex-wrap m-3"
            >
                <div class="flex-1 d-flex align-center mr-4" @click="fixture.homeTeamId ? goToProfile(fixture.homeTeamId) : null">
                    <v-img contain :max-width="$vuetify.breakpoint.xsOnly ? 50 : 80" :src="`/assets/gaa/teams/icons/${fixture.homeLogo}.WEBP`" :alt="fixture.homeTeam" class="img_border"></v-img>
                    <div class="flex-grow-1 ml-2">
                        <p class="font-weight-semi ma-0 ">
                            {{ fixture.homeTeam }}
                        </p>
                        <span class="text--disabled">{{ fixture.subTitle }}</span>
                    </div>
                </div>
                <div v-if="!$vuetify.breakpoint.xsOnly" class="flex-1 text-center">
                    <p class="font-weight-semi ma-0">
                            {{ fixture.stage }}
                    </p>
                    <span class="text--disabled">{{ fixture.venue }}</span>
                </div>
                <div class="flex-1 d-flex align-center" @click="fixture.awayTeamId ? goToProfile(fixture.awayTeamId) : null">
                    <div class="flex-grow-1 text-right mr-2">
                        <p class="font-weight-semi ma-0 ">
                            {{ fixture.awayTeam }}
                        </p>
                        <span class="text--disabled">{{ fixture.subTitle }}</span>
                    </div>
                    <v-img contain :max-width="$vuetify.breakpoint.xsOnly ? 50 : 80" :src="`/assets/gaa/teams/icons/${fixture.awayLogo}.WEBP`" :alt="fixture.awayTeam" class="img_border"></v-img>
                </div>
                <div v-if="$vuetify.breakpoint.xsOnly" class="flex-1 mt-4 text-center" style="min-width: 263px">
                    <span class="text--disabled">{{ fixture.venue }}</span>
                    <p class="font-weight-semi ma-0">
                            {{ fixture.stage }}
                    </p>
                </div>
            </div>
            <v-divider class="mt-5 mb-3" />
            <div class="d-flex flex-wrap">
                    <small class="">{{ fixture.date }}</small>
                    <v-spacer/>
                    <div v-if="fixture.coverage">
                        <v-img contain :max-width="$vuetify.breakpoint.xsOnly ? 50 : 80" :src="`/assets/tv/logo/${fixture.coverage}.jpg`" :alt="fixture.coverage"/>
                    </div>
                    <v-spacer/>
                    <small class="">{{ fixture.time }}</small>
            </div>
        </v-card-text>
    </base-card>
</template>
<script>
export default {
    props: {
        // Floating Button icon Color
        fixture: {
            type: Object,
            required: true
        },
        footerBtn: {
            type: String,
            default: 'Chat'
        },
        footerBtnTwo: {
            type: String,
            default: 'Profile'
        },
        footerBtnColor: {
            type: String,
            default: ''
        },
        footerBtnColorTwo: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    methods: {
      goToProfile(id){
          this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/profile/${id}`)
      }
    }
}
</script>

<style>

.img_border{
    border-style: solid;
    border-width: 2px;
}

</style>
